import React from "react";
import PropTypes from "prop-types";
import "./DatePickerStickyHeader.scss";
import dayjs from "dayjs";
import LocaleData from "dayjs/plugin/localeData";
import { getWeekdaysStartingFromMonday } from "../dateFormatting";

dayjs.extend(LocaleData);

const propTypes = {
  title: PropTypes.string,
  fromDateText: PropTypes.string,
  isFromDateSelected: PropTypes.bool,
  toDateText: PropTypes.string,
  isToDateSelected: PropTypes.bool,
  onClearDates: PropTypes.func,
};

const DatePickerStickyHeader = ({
  title,
  fromDateText,
  isFromDateSelected,
  toDateText,
  isToDateSelected,
}) => {
  const daysOfWeek = getWeekdaysStartingFromMonday();

  return (
    <div className="DatePickerStickyHeader">
      <div className="DatePickerStickyHeader-body">
        <h3 className="DatePickerStickyHeader-title">{title}</h3>
        <div className="DatePickerStickyHeader-dates">
          <span
            className={
              isFromDateSelected ? "DatePickerStickyHeader-dates--active" : ""
            }
            data-testid="DatePickerStickyHeader-fromDatePreview"
          >
            {fromDateText}
          </span>{" "}
          -{" "}
          <span
            className={
              isToDateSelected ? "DatePickerStickyHeader-dates--active" : ""
            }
            data-testid="DatePickerStickyHeader-toDatePreview"
          >
            {toDateText}
          </span>
        </div>
        <div className="DatePickerStickyHeader-weekdays">
          {daysOfWeek.map((weekday) => (
            <div key={weekday} className="DatePickerStickyHeader-weekdays-day">
              {weekday}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

DatePickerStickyHeader.propTypes = propTypes;
export default DatePickerStickyHeader;
