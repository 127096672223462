import React, { useState } from "react";
import "./DateRangePickerMobile.scss";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import classNames from "classnames";
import dayjs from "dayjs";
import { useI18n } from "../../../spages/spa/context/I18nContext";
import { Button } from "../../DesignSystem/Button/Button";
import { FROM_DATE, TO_DATE } from "../constants";
import { formatDatePreviews } from "../dateFormatting";
import DatePickerStickyHeader from "./DatePickerStickyHeader";

const DateRangePicker = loadable(() => import("../DateRangePicker"), {
  ssr: false,
});

const NUM_OF_MONTHS = 12;

const propTypes = {
  minBookingDuration: PropTypes.number,
  minDate: PropTypes.objectOf((propValue) => dayjs.isDayjs(propValue)),
  maxYear: PropTypes.number,
  focusedInput: PropTypes.oneOf([FROM_DATE, TO_DATE]),
  fromDate: PropTypes.objectOf((propValue) => dayjs.isDayjs(propValue)),
  toDate: PropTypes.objectOf((propValue) => dayjs.isDayjs(propValue)),
  onChangeDates: PropTypes.func.isRequired,
  onClearDates: PropTypes.func.isRequired,
  isDayBlocked: PropTypes.func,
};

const DateRangePickerMobile = ({
  minBookingDuration = 1,
  minDate = dayjs(),
  maxYear = dayjs().add(5, "year").year(),
  focusedInput = FROM_DATE,
  fromDate,
  toDate,
  onChangeDates,
  onClearDates,
  isDayBlocked,
}) => {
  const [numberOfMonthsToShow, setNumberOfMonthsToShow] =
    useState(NUM_OF_MONTHS);

  const { t } = useI18n();

  const currentYear = dayjs().year();
  const maxNumOfMonthsToShow = 12 * (maxYear - currentYear);

  const { fromDatePreview, toDatePreview } = formatDatePreviews({
    fromDate,
    toDate,
    defaultFromString: t("components.DateRangePicker.moveIn"),
    defaultToString: t("components.DateRangePicker.moveOut"),
  });

  const isLoadMoreButtonShown = numberOfMonthsToShow < maxNumOfMonthsToShow;

  return (
    <div className="DateRangePickerMobile-drawer-children">
      <DatePickerStickyHeader
        title={t("components.DateRangePicker.whenAreYouMoving")}
        fromDateText={fromDatePreview}
        isFromDateSelected={!!fromDate}
        toDateText={toDatePreview}
        isToDateSelected={!!toDate}
        onClearDates={onClearDates}
      />
      <DateRangePicker
        minBookingDuration={minBookingDuration}
        numberOfMonthsToShow={numberOfMonthsToShow}
        minDate={minDate}
        maxYear={maxYear}
        fromDate={fromDate}
        toDate={toDate}
        focusedInput={focusedInput}
        onChangeDates={onChangeDates}
        isDayBlocked={isDayBlocked}
        rootClassName={classNames("DateRangePickerMobile-root", {
          "DateRangePickerMobile-root--bottom": !isLoadMoreButtonShown,
        })}
        monthsClassName="DateRangePickerMobile-months"
        monthClassName="DateRangePickerMobile-month"
        captionClassName="DateRangePickerMobile-caption"
        headClassName="DateRangePickerMobile-head"
        navClassName="DateRangePickerMobile-nav"
      />
      {isLoadMoreButtonShown && (
        <Button
          dataTestId="DateRangePickerMobile-loadMoreMonthsButton"
          colorVariant="secondary"
          size="large"
          className="DateRangePickerMobile-loadMoreButton"
          onClick={() => {
            setNumberOfMonthsToShow(numberOfMonthsToShow + NUM_OF_MONTHS);
          }}
        >
          {t("components.DateRangePicker.showMore")}
        </Button>
      )}
    </div>
  );
};

DateRangePickerMobile.propTypes = propTypes;
export default DateRangePickerMobile;
export { NUM_OF_MONTHS };
