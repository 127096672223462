import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const propTypes = {
  error: PropTypes.any,
  className: PropTypes.string,
  endAdornment: PropTypes.node,
  children: PropTypes.any,
};

const InputText = React.forwardRef(
  ({ error, className, children, endAdornment, ...props }, ref) => {
    return (
      <div className={classNames("InputText", className)}>
        <input
          ref={ref}
          type="text"
          className={classNames("InputText-input", {
            "InputText-input--error": error,
            "InputText-input--withAdornment": endAdornment,
          })}
          {...props}
        />
        {endAdornment && (
          <div className="InputText-adornment">{endAdornment}</div>
        )}
        {children}
      </div>
    );
  },
);

InputText.propTypes = propTypes;

export default InputText;
