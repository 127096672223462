import React, { forwardRef, useImperativeHandle, useRef } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import classNames from "classnames";
import dayjs from "dayjs";
import { useI18n } from "../../../spages/spa/context/I18nContext";
import { Button } from "../../DesignSystem/Button/Button";
import Popover, { placements } from "../../Popover/Popover";
import { FROM_DATE, TO_DATE } from "../constants";
import { formatDatePreviews } from "../dateFormatting";
import DateRangePickerMobile from "../DateRangePickerMobile/DateRangePickerMobile";
import useDateRangePicker from "../useDateRangePickerTrigger";
import "./DateRangePickerTrigger.scss";

const DateRangePicker = loadable(() => import("../DateRangePicker"), {
  ssr: false,
});

const propTypes = {
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  minBookingDuration: PropTypes.number,
  minDate: PropTypes.objectOf((propValue) => dayjs.isDayjs(propValue)),
  maxYear: PropTypes.number,
  isDayBlocked: PropTypes.func,
  dateRangeSeparator: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  showApplyButton: PropTypes.bool,
  applyButtonText: PropTypes.string,
  onApplyButtonClick: PropTypes.func,
  isMobile: PropTypes.bool,
  popoverClassName: PropTypes.string,
  triggerClassName: PropTypes.string,
  previewButtonClassName: PropTypes.string,
  previewButtonActiveClassName: PropTypes.string,
  previewButtonInactiveClassName: PropTypes.string,
  previewButtonSelectedClassName: PropTypes.string,
  previewButtonAppliedClassName: PropTypes.string,
  previewButtonClearedClassName: PropTypes.string,
  dateSeparatorClassName: PropTypes.string,
  dateSeparatorInactiveClassName: PropTypes.string,
  dateCtaPanelClassName: PropTypes.string,
};

const DateRangePickerTrigger = forwardRef(
  (
    {
      fromDate,
      toDate,
      minBookingDuration = 1,
      minDate,
      maxYear,
      isDayBlocked,
      dateRangeSeparator,
      onChange,
      onClose = () => {},
      showApplyButton = false,
      applyButtonText,
      onApplyButtonClick = () => {},
      isMobile,
      popoverClassName,
      triggerClassName,
      previewButtonClassName,
      previewButtonActiveClassName,
      previewButtonInactiveClassName,
      previewButtonSelectedClassName,
      previewButtonAppliedClassName,
      previewButtonClearedClassName,
      dateSeparatorClassName,
      dateSeparatorInactiveClassName,
      dateCtaPanelClassName,
    },
    ref,
  ) => {
    const {
      tempFromDate,
      tempToDate,
      focusedInput,
      hoveredFromDate,
      hoveredToDate,
      isVisible,
      setIsVisible,
      getFromDate,
      getToDate,
      handleApply,
      onClearDatesHandler,
      onChangeDatesHandler,
      onHoverDatesHandler,
      onPopoverCloseHandler,
      onFromDatePreviewClickHandler,
      onToDatePreviewClickHandler,
    } = useDateRangePicker({
      fromDate,
      toDate,
      showApplyButton: showApplyButton || isMobile,
      onApplyButtonClick,
      onChange,
      onClose,
    });
    const fromPreviewButtonRef = useRef();
    const { t } = useI18n();

    useImperativeHandle(ref, () => ({
      focus: () => {
        setIsVisible(true);
        fromPreviewButtonRef?.current.focus();
      },
    }));

    const fromDateValue = getFromDate() ?? hoveredFromDate ?? null;
    const toDateValue = getToDate() ?? hoveredToDate ?? null;

    const { fromDatePreview, toDatePreview } = formatDatePreviews({
      fromDate: fromDateValue,
      toDate: toDateValue,
      defaultFromString: t("components.DateRangePicker.moveIn"),
      defaultToString: t("components.DateRangePicker.moveOut"),
    });

    let defaultMonth = dayjs();
    if (focusedInput === TO_DATE && toDate) {
      defaultMonth = dayjs(toDate);
    } else if (fromDate) {
      defaultMonth = dayjs(fromDate);
    }

    const fromDateClassNames = classNames(previewButtonClassName, {
      [previewButtonInactiveClassName]: !isVisible && !fromDate,
      [previewButtonActiveClassName]:
        isVisible && focusedInput === FROM_DATE && (!tempFromDate || !fromDate),
      [previewButtonSelectedClassName]: isVisible && tempFromDate && fromDate,
      [previewButtonAppliedClassName]: !isVisible && fromDate,
      [previewButtonClearedClassName]: isVisible && !tempFromDate,
    });

    const toDateClassNames = classNames(previewButtonClassName, {
      [previewButtonInactiveClassName]: !isVisible && !toDate,
      [previewButtonActiveClassName]:
        (isVisible && focusedInput === TO_DATE && (!tempToDate || !toDate)) ||
        (isVisible && tempFromDate && !tempToDate),
      [previewButtonSelectedClassName]:
        isVisible && focusedInput === TO_DATE && tempToDate,
      [previewButtonAppliedClassName]: !isVisible && tempToDate,
      [previewButtonClearedClassName]: isVisible && !tempToDate,
    });

    const separatorClassName = classNames(dateSeparatorClassName, {
      [dateSeparatorInactiveClassName]: !fromDate,
    });

    const isFooterShown = showApplyButton ? tempFromDate : fromDate;

    const datePickerDefaultProps = {
      fromDate: getFromDate({ parseWithDayjs: true }),
      toDate: getToDate({ parseWithDayjs: true }),
      onChangeDates: onChangeDatesHandler,
      focusedInput,
      minBookingDuration,
      minDate,
      maxYear,
      isDayBlocked,
      defaultMonth,
    };

    return (
      <Popover
        preventAutofocus
        showInDrawer={isMobile}
        drawerHeightPercent={100}
        show={isVisible}
        placement={placements.bottomStart}
        usePortal={false}
        hasArrow={false}
        className={classNames(
          "DateRangePickerTrigger-popover",
          popoverClassName,
        )}
        onClose={onPopoverCloseHandler}
        around={
          <div
            onClick={() => setIsVisible(true)}
            className={triggerClassName}
            data-testid="DateRangePickerTrigger"
          >
            <button
              ref={fromPreviewButtonRef}
              onClick={onFromDatePreviewClickHandler}
              className={fromDateClassNames}
              data-testid="DateRangePickerTrigger-fromDate"
              type="button"
            >
              {fromDatePreview}
            </button>
            <span className={separatorClassName}>{dateRangeSeparator}</span>
            <button
              onClick={onToDatePreviewClickHandler}
              className={toDateClassNames}
              data-testid="DateRangePickerTrigger-toDate"
              type="button"
            >
              {toDatePreview}
            </button>
          </div>
        }
      >
        {isMobile ? (
          <DateRangePickerMobile
            {...datePickerDefaultProps}
            onClearDates={onClearDatesHandler}
          />
        ) : (
          <DateRangePicker
            {...datePickerDefaultProps}
            hoveredFromDate={hoveredFromDate}
            hoveredToDate={hoveredToDate}
            onHoverDates={onHoverDatesHandler}
          />
        )}
        <footer
          className={classNames(
            "DateRangePickerTrigger-footer",
            isFooterShown && dateCtaPanelClassName,
            {
              "DateRangePickerTrigger-footer--mobile": isMobile,
            },
          )}
        >
          {isFooterShown ? (
            <React.Fragment>
              <Button
                dataTestId="DataRangePickerTrigger-clearDates"
                colorVariant={showApplyButton ? "text" : "secondary"}
                widthVariant="growWithText"
                size="medium"
                onClick={onClearDatesHandler}
              >
                {t("components.DateRangePicker.clearDates")}
              </Button>
              {showApplyButton && (
                <Button
                  dataTestId="DataRangePickerTrigger-apply"
                  size="large"
                  onClick={handleApply}
                >
                  {applyButtonText || t("apply")}
                </Button>
              )}
            </React.Fragment>
          ) : (
            t("components.DateRangePicker.minBookingDuration", {
              minBookingDuration,
            })
          )}
        </footer>
      </Popover>
    );
  },
);

DateRangePickerTrigger.propTypes = propTypes;
export default DateRangePickerTrigger;
