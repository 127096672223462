import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import DateRangePickerTrigger from "../../../../../../components/DateRangePicker/DateRangePickerTrigger/DateRangePickerTrigger";
import "./SearchBarDateRangePicker.scss";
import { useI18n } from "../../../../context/I18nContext";

const propTypes = {
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const SearchBarDateRangePicker = forwardRef(
  ({ fromDate, toDate, onChange, onClose }, ref) => {
    const { t } = useI18n();

    return (
      <div className="SearchBarForm-Input SearchBarDateRangePicker SearchBarForm-Input-DateRangePicker">
        <div className="SearchBarDateRangePicker-label">
          {t("components.DateRangePicker.stayDuration")}
        </div>
        <DateRangePickerTrigger
          ref={ref}
          fromDate={fromDate}
          toDate={toDate}
          onClose={onClose}
          onChange={onChange}
          dateRangeSeparator="-"
          triggerClassName="SearchBarDateRangePicker-trigger"
          popoverClassName="SearchBarDateRangePicker-popover"
          previewButtonClassName="SearchBarDateRangePicker-previewDateButton"
          previewButtonActiveClassName="SearchBarDateRangePicker-previewDateButton--active"
          previewButtonInactiveClassName="SearchBarDateRangePicker-previewDateButton--inactive"
          previewButtonSelectedClassName="SearchBarDateRangePicker-previewDateButton--selected"
          dateSeparatorClassName="SearchBarDateRangePicker-dateSeparator"
          dateSeparatorInactiveClassName="SearchBarDateRangePicker-dateSeparator--inactive"
          dateCtaPanelClassName="SearchBarDateRangePicker-ctaPanel"
        />
      </div>
    );
  },
);

SearchBarDateRangePicker.propTypes = propTypes;
export default SearchBarDateRangePicker;
