/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./SliderArrow.scss";

const SliderArrow = ({ width = 16, height = 14, fillColor, className }) => {
  return (
    <svg
      className={classnames("SliderArrow", className)}
      width={width}
      height={height}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.94586 0.699463L6.30959 0.699463L11.6319 6.02174L0.800781 6.02174L0.800781 7.88703L11.6319 7.88703L6.30959 13.2093H8.94586L15.2008 6.95439L8.94586 0.699463Z"
        fill={fillColor}
      />
    </svg>
  );
};

SliderArrow.propTypes = {
  fillColor: PropTypes.string,
  direction: PropTypes.oneOf(["right", "left"]),
  width: PropTypes.number,
  height: PropTypes.number,
};

export default SliderArrow;
